<template>
  <div class="c-coffeeMap">
    <div class="c-coffeeMap__label c-coffeeMap__label--left">酸味</div>
    <div class="c-coffeeMap__label c-coffeeMap__label--right">苦味</div>
    <div class="c-coffeeMap__label c-coffeeMap__label--top">ライト</div>
    <div class="c-coffeeMap__label c-coffeeMap__label--bottom">ストロング</div>
    <div class="c-coffeeMap__cells">
      <div
        v-for="(gradationValue, cellId) in gradationValues"
        :key="cellId + 1"
        class="c-coffeeMap__cell"
        :class="cellClasses(cellId + 1)"
        :style="cellStyles(gradationValue)"
        @click="onClickCell(cellId + 1)">
        <div>
          <div class="c-coffeeMap__balloon" v-if="activeCellId === cellId + 1">
            <ImpressionBalloon
              :like="mapValues[cellId].count.like"
              :hate="mapValues[cellId].count.hate" />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="overlay"
      class="c-coffeeMapOverlay"
      @click="$emit('click:overlay')">
      <div class="c-coffeeMapOverlay__title">まずはココをタップ</div>
      <div class="c-coffeeMapOverlay__icon">
        <img src="/assets/img/coffee-map/gesture_double_tap.svg" alt="" />
      </div>
      <div class="c-coffeeMapOverlay__message">
        エリアをタップして<br />味覚マップを設定しよう
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
  name: 'MyCoffeeMap',

  components: {
    ImpressionBalloon: () =>
      import('@/components/mypage/coffeeMap/ImpressionBalloon.vue')
  },

  props: {
    /**
     * HSL形式のカラーオブジェクト
     * @type {{HUE: number, SATURATION: number, LIGHTNESS: number}}
     */
    color: {
      type: Object,
      required: true
    },

    /**
     * カラーリングの有無
     */
    isColored: {
      type: Boolean,
      default: true
    },

    /**
     * アクティブセルID（1〜36）
     * @type {number|null}
     */
    activeCellId: {
      required: true,
      validator: (prop) => typeof prop === 'number' || prop === null
    },

    /**
     * マップ表示に必要なオブジェクトの配列
     * @type {Object[]}
     */
    mapValues: {
      type: Array,
      required: true
    },

    overlay: {
      type: Boolean,
      default: false
    }
  },

  setup: (props, context) => {
    /**
     * 階調
     */
    const GRADIENT = 12;

    /**
     * マップの最大色と最小色の差
     * @returns {{hue: number, saturation: number, lightness: number}}
     */
    const diff = computed(() => {
      return {
        hue: props.color.MAX.HUE - props.color.MIN.HUE,
        saturation: props.color.MAX.SATURATION - props.color.MIN.SATURATION,
        lightness: props.color.MAX.LIGHTNESS - props.color.MIN.LIGHTNESS
      };
    });

    /**
     * 指定階調数に加工したマッププロット値
     * @returns {number[]}
     */
    const gradationValues = computed(() => {
      const STEP = 1 / GRADIENT; // 1階調あたりのステップ値
      return props.mapValues.map((mapValue) => {
        const stepNum = Math.floor(mapValue.val / STEP); // ステップ数（0〜GRADIENTの整数値）
        return floor(stepNum * STEP, 100);
      });
    });

    /**
     * 重みに応じてセルの背景色（hsl)を返す
     * @param {number} weight 各セルのマッププロット値（重み）
     * @returns {string} 背景色（hsl)
     */
    const hsl = (weight) => {
      if (weight === -1) return; // 非活性セル（コーヒーがないセル）
      if (!props.isColored) return; // カラーリング設定が無効な場合
      if (weight === 0) return; // 重みが0の場合

      const hue = props.color.MIN.HUE + diff.value.hue * weight;
      const saturation =
        props.color.MIN.SATURATION + diff.value.saturation * weight;
      const lightness =
        props.color.MIN.LIGHTNESS + diff.value.lightness * weight;

      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };

    /**
     * セルに適用するclassオブジェクトを返す
     * @param {number} cellId セルID
     * @returns {Object} セルに適用するclassオブジェクト
     */
    const cellClasses = (cellId) => {
      return {
        'c-coffeeMap__cell--active': props.activeCellId === cellId,
        'c-coffeeMap__cell--disabled': props.mapValues[cellId - 1].val === -1
      };
    };

    /**
     * セルに適用するstyleオブジェクトを返す
     * @param {number} weight 各セルのマッププロット値（重み）
     * @returns {string} セルに適用するstyleオブジェクト
     */
    const cellStyles = (weight) => {
      return { background: `${hsl(weight)}` };
    };

    /**
     * グレーアウトしていないセルをクリックした際に、click:cellイベントを送出する
     * @param {number} cellId セルID
     */
    const onClickCell = (cellId) => {
      if (props.mapValues[cellId - 1].val === -1) return;

      context.emit('click:cell', cellId);
    };

    /**
     * 任意の桁で切り捨てする関数
     * @param {number} value 切り捨てする数値
     * @param {number} base どの桁で切り捨てするか（100→小数点第3位、0.1→第1位）
     * @returns {number} 切り捨てした値
     */
    const floor = (value, base) => {
      return Math.floor(value * base) / base;
    };

    return {
      gradationValues,
      cellClasses,
      cellStyles,
      onClickCell
    };
  }
});
</script>
